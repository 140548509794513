import React, { useEffect, useState } from 'react';

const Mask = ({ isVisible, onClose }) => {
  const [shouldRenderMask, setShouldRenderMask] = useState(isVisible);

  useEffect(() => {
    // Funci�n que verifica el tama�o de la pantalla
    const handleResize = () => {
      if (window.innerWidth < 1100) {
        setShouldRenderMask(false);  // Desactivar la m�scara si la pantalla es menor a 1100px
      } else {
        setShouldRenderMask(isVisible);  // Usar el valor de isVisible si la pantalla es mayor o igual a 1100px
      }
    };

    // Ejecuta la funci�n una vez cuando se monta el componente
    handleResize();

    // Agrega el evento para escuchar cambios en el tama�o de la ventana
    window.addEventListener('resize', handleResize);

    // Limpia el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isVisible]); // Dependencia para que se actualice si cambia `isVisible`

  if (!shouldRenderMask) return null;  // Si no debe mostrarse, no renderiza el componente

  return (
    <div
      onClick={onClose}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 10,
        cursor: 'pointer',
      }}
    />
  );
};

export default Mask;
