// AppContext.js
import React, { createContext, useState } from 'react';

// Crea el contexto
export const AppContext = createContext();

// Componente proveedor del contexto
export const AppProvider = ({ children }) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <AppContext.Provider value={{ inputValue, setInputValue }}>
      {children}
    </AppContext.Provider>
  );
};
