import React from 'react';

const ProgressBar = ({ percentage }) => {
  const steps = 5;
  const filledSteps = Math.floor((percentage / 100) * steps);

  return (
    <div className="mt-6 mb-4">
      <div className="relative h-8 bg-[#abc5d9] rounded-full overflow-hidden border border-[#82c8ff]">
        <div 
          className="absolute top-0 left-0 h-full bg-green-400 rounded-full transition-all duration-300 ease-out flex items-center justify-end pr-2"
          style={{ width: `${percentage}%` }}
        >
          {percentage > 0 && (
            <span className="text-white font-bold text-sm">{percentage}%</span>
          )}
        </div>
        <div className="absolute top-0 left-0 w-full h-full flex justify-between items-center px-2">
          {[...Array(steps)].map((_, index) => (
            <div 
              key={index}
              className={`w-6 h-6 rounded-full border-2 ${
                index < filledSteps ? 'bg-green-500 border-green-600' : 'bg-white border-gray-400'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;