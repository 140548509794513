import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { AppProvider } from './AppContext';
import FilterMenu from './FilterMenu';
import AdvancedFilters from './AdvancedFilters';
import PlanList from './components/PlanList';
import BanmedicaLogo from './assets/Banmedica.webp';
import ColmenaLogo from './assets/Colmena.webp';
import EsencialLogo from './assets/Esencial.webp';
import NuevaMasVidaLogo from './assets/NuevaMasvida.webp';
import VidatresLogo from './assets/Vidatres.webp';
import BannerImage from './assets/banner.webp';
import InfoPlanLogo from './assets/logoinfo.webp';
import planService from './services/planService';
import './App.css';
import ExecutiveDashboard from './components/ExecutiveDashboard';
import DashboardAdmin from './components/DashboardAdmin';
import { useMediaQuery } from 'react-responsive';
import Mask from './Mask';

const App = () => {
  const [esMovil, setEsMovil] = useState(window.innerWidth <= 1100);
  const [filtros, setFiltros] = useState({});
  const [planes, setPlanes] = useState([]);
  const [vacio, setVacio] = useState(false);
  const [error, setError] = useState(null);
  const [precioMinimo, setPrecioMinimo] = useState(0);
  const [precioSlider, setPrecioSlider] = useState(0);
  const referenciaFiltrosPrevios = useRef(filtros);
  const cargaInicialRealizada = useRef(false);
  const [clinicaSeleccionada, setClinicaSeleccionada] = useState('');
  const [region, setRegion] = useState('');
  const [coberturaHospitalariaMinima, setCoberturaHospitalariaMinima] = useState('60');
  const [coberturaAmbulatoriaMinima, setCoberturaAmbulatoriaMinima] = useState('60');
  const [mostrarDashboard, setMostrarDashboard] = useState(false);
  const [mostrarDashboardAdmin, setMostrarDashboardAdmin] = useState(false);
  const [ejecutivoActivo, setEjecutivoActivo] = useState('');
  const [isMaskVisible, setIsMaskVisible] = useState(true);
  const [filterMenuHeight, setFilterMenuHeight] = useState(0);
  const filterMenuRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isFilterMenuComplete, setIsFilterMenuComplete] = useState(false);
  const advancedFiltersRef = useRef(null);
  const [hasShownModal, setHasShownModal] = useState(false);

  const logotiposIsapre = {
    Banmedica: BanmedicaLogo,
    Colmena: ColmenaLogo,
    Esencial: EsencialLogo,
    'Nueva Masvida': NuevaMasVidaLogo,
    Vidatres: VidatresLogo,
  };

  useEffect(() => {
    const manejarCambioTamanio = () => {
      console.log("Tamaño de pantalla cambiado, esMovil:", window.innerWidth <= 1100);
      setEsMovil(window.innerWidth <= 1100);
    };
    window.addEventListener('resize', manejarCambioTamanio);
    return () => {
      window.removeEventListener('resize', manejarCambioTamanio);
    };
  }, []);

  useEffect(() => {
    const obtenerEjecutivoActivo = async () => {
      try {
        const ejecutivo = await planService.obtenerEjecutivoActivo();
        console.log("Ejecutivo activo obtenido:", ejecutivo);
        setEjecutivoActivo(ejecutivo);
      } catch (error) {
        console.error("Error al obtener el ejecutivo activo:", error);
      }
    };

    obtenerEjecutivoActivo();
  }, []);

  useEffect(() => {
    if (filterMenuRef.current) {
      setFilterMenuHeight(filterMenuRef.current.offsetHeight);
      console.log('Valor de zIndex del FilterMenu dentro de App:', filterMenuRef.current.style.zIndex);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      console.log("Scroll detectado");
      console.log("isFilterMenuComplete:", isFilterMenuComplete);
      console.log("hasShownModal:", hasShownModal);

      if (!isFilterMenuComplete && !hasShownModal) {
        const scrollPosition = window.scrollY;
        const pageHeight = document.documentElement.scrollHeight;
        console.log("scrollPosition:", scrollPosition, "pageHeight:", pageHeight);

        if (scrollPosition > pageHeight / 4) {
          console.log("Mostrando modal debido al scroll");
          setShowModal(true);
          setHasShownModal(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isFilterMenuComplete, hasShownModal]);

  const obtenerPlanes = useCallback(async (filtrosActuales) => {
    console.log("Iniciando obtenerPlanes con filtros:", filtrosActuales);
    console.log("Estado inicial de vacio:", vacio);

    if (JSON.stringify(filtrosActuales) === JSON.stringify(referenciaFiltrosPrevios.current)) {
      console.log("Evitando búsqueda duplicada.");
      console.log("Estado de vacio tras evitar búsqueda duplicada:", vacio);
      return;
    }

    setIsLoading(true);
    setError(null);
    setVacio(false);
    console.log("Estado de vacio después de resetear a false:", vacio);

    const filtrosSQL = {
      edad_cotizante1: filtrosActuales.edad_cotizante1 || null,
      edad_cotizante2: filtrosActuales.edad_cotizante2 || null,
      edades_cargas: filtrosActuales.edades_cargas || null,
      sexo: filtrosActuales.sexo || null,
      clinicas: filtrosActuales.clinicas || [],
      region: filtrosActuales.region || null,
      tipo_de_plan: filtrosActuales.tipo_de_plan || null,
      isapre: filtrosActuales.isapre || null,
      precio_minimo_clp: filtrosActuales.precio_minimo_clp || null,
      cargas: filtrosActuales.edades_cargas ? filtrosActuales.edades_cargas.split(',').length : 0,
      cobertura_hospitalaria_minima: filtrosActuales.cobertura_hospitalaria_minima || coberturaHospitalariaMinima,
      cobertura_ambulatoria_minima: filtrosActuales.cobertura_ambulatoria_minima || coberturaAmbulatoriaMinima
    };

    console.log("Filtros preparados para SQL:", filtrosSQL);

    try {
      const { planes, vacio: esVacio } = await planService.filtrarPlanes(filtrosSQL);
      console.log("Valor de vacio recibido del servicio:", esVacio);

      setVacio(esVacio);
      console.log("Estado de vacio después de setVacio:", vacio);

      setPlanes(planes || []);
      referenciaFiltrosPrevios.current = filtrosActuales;
    } catch (error) {
      console.error('Error al obtener los planes:', error);
      setError('Hubo un error al obtener los planes. Por favor, intente de nuevo.');
      setPlanes([]);
      console.log("Estado de vacio en caso de error:", vacio);
    } finally {
      setIsLoading(false);
    }
  }, [coberturaHospitalariaMinima, coberturaAmbulatoriaMinima]);

  const obtenerPlanesConRetraso = useMemo(() => {
    const funcionConRetraso = (filtrosActuales) => {
      console.log("Aplicando retraso a obtenerPlanes");
      const temporizador = setTimeout(() => {
        obtenerPlanes(filtrosActuales);
      }, 300);
      return () => clearTimeout(temporizador);
    };
    return funcionConRetraso;
  }, [obtenerPlanes]);

  useEffect(() => {
    console.log("Componente montado. Estado de vacio:", vacio);

    if (!cargaInicialRealizada.current) {
      console.log("Realizando carga inicial de planes");
      const filtrosIniciales = { region: '' };
      obtenerPlanes(filtrosIniciales);
      cargaInicialRealizada.current = true;
      console.log("Carga inicial realizada. Estado de vacio:", vacio);
    } else if (Object.keys(filtros).length > 0) {
      console.log("Filtros actualizados, llamando a obtenerPlanesConRetraso. Estado de vacio antes de la llamada:", vacio);
      obtenerPlanesConRetraso(filtros);
      console.log("Después de llamar a obtenerPlanesConRetraso. Estado de vacio:", vacio);
    }
  }, [filtros, obtenerPlanesConRetraso, obtenerPlanes]);

  const manejarCambioFiltro = useCallback((nuevosFiltros) => {
    console.log("manejarCambioFiltro recibe los siguientes filtros:", nuevosFiltros);

    setFiltros((filtrosPrevios) => {
      const filtrosActualizados = { ...filtrosPrevios };

      Object.entries(nuevosFiltros).forEach(([clave, valor]) => {
        if (clave === 'clinicas') {
          filtrosActualizados.clinicas = valor;
          setClinicaSeleccionada(valor);
        } else if (clave === 'edad_cotizante1' || clave === 'edad_cotizante2') {
          filtrosActualizados[clave] = valor === '' ? null : valor;
        } else if (clave === 'edades_cargas') {
          filtrosActualizados[clave] = valor === '' ? null : valor;
        } else if (clave === 'tipo_de_plan') {
          filtrosActualizados.tipo_de_plan = valor;
        } else if (clave === 'region') {
          filtrosActualizados.region = valor;
          setRegion(valor);
        } else if (clave === 'cobertura_hospitalaria_minima') {
          filtrosActualizados.cobertura_hospitalaria_minima = valor;
          setCoberturaHospitalariaMinima(valor);
        } else if (clave === 'cobertura_ambulatoria_minima') {
          filtrosActualizados.cobertura_ambulatoria_minima = valor;
          setCoberturaAmbulatoriaMinima(valor);
        } else if (valor === undefined || valor === '') {
          console.warn(`Eliminando filtro indefinido o vacío: ${clave}`);
          delete filtrosActualizados[clave];
        } else {
          filtrosActualizados[clave] = valor;
        }
      });

      console.log("manejarCambioFiltro actualiza los filtros a:", filtrosActualizados);
      checkFilterMenuCompletion(filtrosActualizados); // Verificación tras cada cambio
      return filtrosActualizados;
    });
  }, []);

  const manejarCambioPrecioMinimo = useCallback((nuevoPrecioMinimo) => {
    console.log("Cambio en precio mínimo:", nuevoPrecioMinimo);
    setPrecioMinimo(nuevoPrecioMinimo);
  }, []);

  const manejarCambioPrecioSlider = useCallback((nuevoPrecioSlider) => {
    console.log("Cambio en slider de precio:", nuevoPrecioSlider);
    setPrecioSlider(nuevoPrecioSlider);
  }, []);

  const cerrarDashboard = useCallback(() => {
    console.log("Cerrando dashboard de ejecutivo.");
    setMostrarDashboard(false);
  }, []);

  const cerrarDashboardAdmin = useCallback(() => {
    console.log("Cerrando dashboard de admin.");
    setMostrarDashboardAdmin(false);
  }, []);

  const handleFilterMenuSubmit = async (formData) => {
    try {
      console.log("Enviando datos del formulario del FilterMenu:", formData);
      const dataToSend = {
        ...formData,
        source: 'FilterMenu'
      };
      const response = await planService.enviarSolicitud(dataToSend);
      console.log('Respuesta del servidor al enviar el formulario:', response);

      // Ocultar el modal si el formulario está completo
      if (isFilterMenuComplete) {
        console.log("Formulario completo, ocultando modal.");
        setShowModal(false);
      } else {
        console.log("Formulario incompleto, manteniendo el modal visible.");
      }
    } catch (error) {
      console.error('Error al enviar los datos del FilterMenu:', error);
    }
  };

  // Modificación aquí: quitar la dependencia de la información del género
  const checkFilterMenuCompletion = (filters) => {
    console.log("Comprobando si el formulario está completo con los filtros:", filters);

    // Modificación: isComplete solo depende del campo region
    const isComplete = !!filters.region; // Si region está presente, isComplete será true
    console.log("Campo region recibido:", filters.region); // Log para saber cuándo se recibe el campo de region

    setIsFilterMenuComplete(isComplete); // Establecer isFilterMenuComplete basado solo en la presencia de region
    console.log("isFilterMenuComplete establecido en:", isComplete); // Log para confirmar el valor de isFilterMenuComplete
  };

  const handleAdvancedFiltersClick = () => {
    console.log("Interacción con filtros avanzados.");
    if (!isFilterMenuComplete && !hasShownModal) {
      console.log("Mostrando modal debido a interacción con filtros avanzados.");
      setShowModal(true);
      setHasShownModal(true);
    }
  };

  const handleCloseModal = () => {
    console.log("Cerrando modal manualmente.");
    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleShowIncompleteModal = () => {
    console.log("Intentando mostrar el modal de formulario incompleto.");
    if (!hasShownModal) {
      console.log("Mostrando modal de formulario incompleto.");
      setShowModal(true);
      setHasShownModal(true);
    }
  };

  console.log("ejecutivoActivo:", ejecutivoActivo);

  if (mostrarDashboard) {
    return <ExecutiveDashboard onClose={cerrarDashboard} />;
  }

  if (mostrarDashboardAdmin) {
    return <DashboardAdmin onClose={cerrarDashboardAdmin} />;
  }

  console.log("Enviando ejecutivoActivo a PlanList:", ejecutivoActivo);

  const SkeletonCard = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const MobileSkeleton = () => (
      <div className="bg-white rounded-lg shadow-md p-4 w-full relative animate-pulse">
        <div className="flex items-center justify-between mb-2">
          <div className="bg-blue-100 h-5 w-20 rounded"></div>
          <div className="bg-blue-100 h-5 w-16 rounded"></div>
        </div>
        <div className="flex flex-col items-start mb-2">
          <div className="bg-gray-200 w-28 h-28 mb-1 rounded"></div>
          <div className="bg-gray-200 h-4 w-3/4 rounded"></div>
        </div>
        <div className="grid grid-cols-2 gap-2 mb-2">
          {[...Array(4)].map((_, index) => (
            <div key={index}>
              <div className="bg-gray-200 h-3 w-16 rounded mb-1"></div>
              <div className="bg-gray-200 h-3 w-24 rounded"></div>
            </div>
          ))}
        </div>
        <div className="bg-gray-200 h-6 w-1/2 mx-auto rounded mb-2"></div>
        <div className="space-y-2">
          <div className="bg-red-200 h-8 w-full rounded"></div>
          <div className="bg-gray-200 h-8 w-full rounded"></div>
          <div className="bg-green-200 h-8 w-full rounded"></div>
        </div>
      </div>
    );

    const DesktopSkeleton = () => (
      <div className="bg-white rounded-lg shadow-md p-4 w-full relative animate-pulse">
        <div className="flex justify-between items-start mb-2">
          <div className="flex flex-col w-[70%] items-start border-r border-gray-300 pr-4">
            <div className="flex items-center mb-2">
              <div className="bg-gray-200 w-20 h-20 rounded mr-3"></div>
              <div>
                <div className="bg-gray-200 h-4 w-32 rounded mb-1"></div>
                <div className="bg-gray-200 h-3 w-24 rounded mb-1"></div>
                <div className="bg-gray-200 h-3 w-20 rounded"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[30%] items-center justify-center">
          <div className="bg-gray-200 h-6 w-24 rounded mb-2"></div>
          <div className="space-y-2 w-full">
            <div className="bg-red-200 h-8 w-full rounded"></div>
            <div className="bg-gray-200 h-8 w-full rounded"></div>
            <div className="bg-green-200 h-8 w-full rounded"></div>
          </div>
        </div>
      </div>
    );

    return isMobile ? <MobileSkeleton /> : <DesktopSkeleton />;
  };

  const toggleMask = () => {
    console.log("Cambiando visibilidad de la máscara.");
    setIsMaskVisible(!isMaskVisible);
  };

  const handleFilterMenuInteraction = () => {
    console.log("Interacción con el menú de filtros.");
    setIsMaskVisible(false);
  };

  return (
    <AppProvider>
      <div className={`min-h-screen relative ${esMovil ? 'bg-white' : 'bg-[#f5f5f5]'}`}>
        <div
          className="absolute top-0 left-0 w-full z-10"
          style={{
            backgroundImage: `url(${BannerImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            height: '220px',
          }}
        ></div>
        <header
          className={`flex items-center py-2 z-20 ${esMovil ? 'flex-col' : 'flex-row justify-start'}`}
          style={{
            marginLeft: esMovil ? '10px' : '50px',
            position: 'relative',
            backgroundColor: 'transparent',
          }}
        >
          <img
            src={InfoPlanLogo}
            alt="InfoPlan Logo"
            style={{
              width: esMovil ? '160px' : '180px',
              height: 'auto',
              display: 'block',
              marginTop: esMovil ? '5px' : '25px',
            }}
          />

          <div
            className={`${esMovil ? 'text-center mt-2' : 'ml-4 text-left'}`}
            style={{
              width: esMovil ? '100%' : 'auto',
              marginLeft: esMovil ? '0' : '50px',
              marginTop: esMovil ? '0' : '20px',
            }}
          >
            <h1 className={`text-4xl lg:text-6xl font-bold ${esMovil ? 'text-[#004677]' : 'text-[#004677]'}`}>
              Planes de Isapre
            </h1>
            <p className={`text-lg lg:text-xl font-bold ${esMovil ? 'text-[#0063af]' : 'text-[#0063af]'} mb-4 mt-2`}>
              Compara y Cotiza todos los Planes de Isapre Online
            </p>
          </div>
        </header>

        <main
          className={`relative z-20 mx-auto px-4 sm:px-6 lg:px-8 ${esMovil ? 'py-2' : 'py-6'} ${
            esMovil ? 'w-full' : 'max-w-[85%]'
          }`}
        >
          <Mask 
            isVisible={isMaskVisible} 
            onClose={toggleMask} 
          />

          <div 
            ref={filterMenuRef}
            className={`mb-8 w-full ${esMovil ? 'mt-4' : '-mt-8'} bg-white rounded-lg shadow-md overflow-hidden`}
          >
            <FilterMenu
              onFilterChange={(filters) => {
                manejarCambioFiltro(filters);
                checkFilterMenuCompletion(filters);
              }}
              onMinPriceChange={manejarCambioPrecioMinimo}
              onSliderPriceChange={manejarCambioPrecioSlider}
              onInteraction={handleFilterMenuInteraction}
              onSubmit={handleFilterMenuSubmit}
              onShowIncompleteModal={handleShowIncompleteModal}
              isMobile={esMovil}
            />
          </div>

          <div className={`${esMovil ? '' : 'bg-white rounded-lg shadow-md p-6'}`}>
            <div className={`flex ${esMovil ? 'flex-col' : 'flex-row'} gap-8`}>
              <div className="lg:w-[24%]" onClick={handleAdvancedFiltersClick} ref={advancedFiltersRef}>
                <AdvancedFilters
                  onFilterChange={manejarCambioFiltro}
                  precioMinimoFilterMenu={precioMinimo}
                  precioslider={precioSlider}
                  initialCoberturaHospitalaria={coberturaHospitalariaMinima}
                  initialCoberturaAmbulatoria={coberturaAmbulatoriaMinima}
                  isMobile={esMovil}
                />
              </div>

              <div className={esMovil ? 'w-full' : 'lg:w-[76%]'}>
                {isLoading ? (
                  <>
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                  </>
                ) : vacio ? (
                  <div className="flex justify-center items-center h-64">
                    <p className="text-2xl font-bold text-[#004677]">
                      No se encontraron planes. Por favor, ajuste los filtros.
                    </p>
                  </div>
                ) : (
                  planes.length > 0 && (
                    <PlanList
                      isapreLogos={logotiposIsapre}
                      planes={planes}
                      filters={filtros}
                      clinicaSeleccionada={clinicaSeleccionada}
                      region={region}
                      cargas={filtros.edades_cargas}
                      ejecutivoActivo={ejecutivoActivo}
                    />
                  )
                )}
              </div>
            </div>
          </div>

          <div className="mt-8 flex justify-center space-x-4">
            <button
              onClick={() => {
                console.log("Abriendo portal de ejecutivos.");
                setMostrarDashboard(true);
              }}
              style={{
                padding: '8px 16px',
                backgroundColor: '#fff',
                color: '#2563eb',
                border: '2px solid #2563eb',
                borderRadius: '5px',
                fontSize: '14px',
                fontWeight: 'bold',
                cursor: 'pointer',
                transition: 'background-color 0.3s, color 0.3s',
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = '#2563eb';
                e.target.style.color = '#fff';
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = '#fff';
                e.target.style.color = '#2563eb';
              }}
            >
              Portal Ejecutivos
            </button>

            <button
              onClick={() => {
                console.log("Abriendo dashboard de administrador.");
                setMostrarDashboardAdmin(true);
              }}
              style={{
                padding: '8px 16px',
                backgroundColor: '#fff',
                color: '#2563eb',
                border: '2px solid #2563eb',
                borderRadius: '5px',
                fontSize: '14px',
                fontWeight: 'bold',
                cursor: 'pointer',
                transition: 'background-color 0.3s, color 0.3s',
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = '#2563eb';
                e.target.style.color = '#fff';
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = '#fff';
                e.target.style.color = '#2563eb';
              }}
            >
              Admin
            </button>

            <button
              onClick={toggleMask}
              style={{
                padding: '8px 16px',
                backgroundColor: '#fff',
                color: '#2563eb',
                border: '2px solid #2563eb',
                borderRadius: '5px',
                fontSize: '14px',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            >
              {isMaskVisible ? '' : ''}
            </button>
          </div>
        </main>

        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[99999]">
            <div className="bg-white p-6 rounded-lg shadow-xl">
              <h2 className="text-xl font-bold mb-4">Atención</h2>
              <p>Para ver los precios exactos completa el formulario de arriba.</p>
              <button
                onClick={handleCloseModal}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Entendido
              </button>
            </div>
          </div>
        )}
      </div>
    </AppProvider>
  );
};

export default App;
