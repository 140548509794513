import React, { useState, useEffect, useRef } from 'react';
import clientService from '../services/clientService';
import io from 'socket.io-client';
import clienteSound from '../assets/cliente.mp3';
import cliente3Sound from '../assets/cliente3.mp3';

const ColorSelector = ({ selectedColor, onColorChange }) => {
  const colors = ['gray', 'red', 'blue', 'green', 'yellow', 'purple', 'pink'];

  return (
    <div className="flex space-x-1">
      {colors.map((color) => (
        <button
          key={color}
          className={`w-4 h-4 rounded-full ${color === 'yellow' ? 'bg-yellow-400' : `bg-${color}-500`} ${selectedColor === color ? 'ring-2 ring-offset-2 ring-black' : ''}`}
          onClick={() => {
            console.log('Color seleccionado:', color);
            onColorChange(color);
          }}
        />
      ))}
    </div>
  );
};

const ExecutiveDashboard = ({ newClient, onClose }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [clients, setClients] = useState(() => {
    const savedClients = localStorage.getItem('clients');
    console.log('Clientes guardados recuperados de localStorage:', savedClients);
    const parsedClients = savedClients ? JSON.parse(savedClients) : [];
    return parsedClients.sort((a, b) => new Date(b.timestamp || b.fecha) - new Date(a.timestamp || a.fecha));
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [clientsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedClients, setExpandedClients] = useState({});
  const [sortOrder, setSortOrder] = useState('recent');
  const [tasks, setTasks] = useState(() => {
    const savedTasks = localStorage.getItem('tasks');
    console.log('Tareas guardadas recuperadas de localStorage:', savedTasks);
    return savedTasks ? JSON.parse(savedTasks) : [];
  });
  const [draggedClient, setDraggedClient] = useState(null);
  const [taskSearchTerm, setTaskSearchTerm] = useState('');
  const [socket, setSocket] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoAlertPlaying, setIsVideoAlertPlaying] = useState(false);
  const audioRef = useRef(new Audio(clienteSound));
  const videoAudioRef = useRef(new Audio(cliente3Sound));
  const [ejecutivoActivo, setEjecutivoActivo] = useState('');

  useEffect(() => {
  if (isLoggedIn) {
    const newSocket = io('https://infoplan.cl:4000');
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Conectado al servidor WebSocket');
      newSocket.emit('registerEjecutivo', username);
    });

    newSocket.on('nuevoCliente', (clienteNuevo) => {
      console.log('Nuevo cliente recibido por WebSocket:', clienteNuevo);
      addOrUpdateClient(clienteNuevo);
      startAlarm();
    });

    newSocket.on('solicitud_videollamada', (data) => {
      console.log('Evento de solicitud_videollamada recibido:', data);
      console.log('Valor de video:', data.video);
      if (data.video === true) {
        console.log('Iniciando alarma de video');
        stopAlarm();
        startVideoAlarm();
      } else {
        console.log('El valor de video no es true, no se inicia la alarma de video');
      }
    });

    return () => newSocket.close();
  }
}, [isLoggedIn, username]);

  useEffect(() => {
    const audio = audioRef.current;
    audio.loop = true;

    if (isPlaying) {
      audio.play().catch(error => console.error("Error playing audio:", error));
    } else {
      audio.pause();
      audio.currentTime = 0;
    }

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [isPlaying]);

  useEffect(() => {
    const videoAudio = videoAudioRef.current;
    videoAudio.loop = true;

    if (isVideoAlertPlaying) {
      videoAudio.play().catch(error => console.error("Error playing video alert audio:", error));
    } else {
      videoAudio.pause();
      videoAudio.currentTime = 0;
    }

    return () => {
      videoAudio.pause();
      videoAudio.currentTime = 0;
    };
  }, [isVideoAlertPlaying]);

  const startAlarm = () => {
    setIsPlaying(true);
    setIsVideoAlertPlaying(false);
  };

  const stopAlarm = () => {
    setIsPlaying(false);
    setIsVideoAlertPlaying(false);
  };

  const startVideoAlarm = () => {
    setIsPlaying(false);
    setIsVideoAlertPlaying(true);
  };

  const addOrUpdateClient = (clienteNuevo) => {
    setClients(prevClients => {
      const updatedClient = { 
        ...clienteNuevo, 
        timestamp: new Date().toISOString(), 
        estado: clienteNuevo.estado || 'No Contactado' 
      };
      console.log('Cliente actualizado:', updatedClient);

      const existingClientIndex = prevClients.findIndex(
        client => client.id === updatedClient.id
      );

      let newClients;
      if (existingClientIndex !== -1) {
        console.log('Cliente existente encontrado en el índice:', existingClientIndex);
        newClients = prevClients.map((client, index) => 
          index === existingClientIndex ? updatedClient : client
        );
      } else {
        console.log('Cliente nuevo, agregando a la lista.');
        newClients = [updatedClient, ...prevClients];
      }

      newClients.sort((a, b) => new Date(b.timestamp || b.fecha) - new Date(a.timestamp || a.fecha));
      console.log('Lista actualizada de clientes:', newClients);
      localStorage.setItem('clients', JSON.stringify(newClients));
      return newClients;
    });
  };

  useEffect(() => {
    if (newClient && isLoggedIn) {
      console.log('Nuevo cliente recibido:', newClient);
      addOrUpdateClient(newClient);
    }
  }, [newClient, isLoggedIn]);

  useEffect(() => {
    console.log('Tareas actualizadas:', tasks);
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  useEffect(() => {
    const fetchClients = async () => {
      if (isLoggedIn && username) {
        console.log('Iniciando fetchClients. Usuario logueado:', username);
        try {
          console.log('Llamando a clientService.getClients con username:', username);
          const fetchedClients = await clientService.getClients(username);
          console.log('Clientes obtenidos desde el backend:', fetchedClients);
          const sortedClients = fetchedClients.sort((a, b) => new Date(b.timestamp || b.fecha) - new Date(a.timestamp || a.fecha));
          setClients(sortedClients);
        } catch (error) {
          console.error('Error fetching clients:', error);
        }
      } else {
        console.log('No se inició fetchClients. isLoggedIn:', isLoggedIn, 'username:', username);
      }
    };
    fetchClients();
  }, [isLoggedIn, username]);

  const handleLogin = async (event) => {
    event.preventDefault();
    console.log('Intento de inicio de sesión con username:', username);
    try {
      const response = await fetch('https://infoplan.cl:4000/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      console.log('Respuesta de inicio de sesión:', data);

      if (response.ok) {
        console.log('Inicio de sesión exitoso para el usuario:', username);
        setIsLoggedIn(true);
        setEjecutivoActivo(username);
      } else {
        console.log('Error en la autenticación para el usuario:', username);
        alert('Error en la autenticación');
      }
    } catch (error) {
      console.error('Error al intentar iniciar sesión:', error);
      alert('Error al intentar iniciar sesión');
    }
  };

  const handleConnect = (clientId) => {
    console.log('Conectar cliente:', clientId);
    setClients(prevClients => {
      const newClients = prevClients.map(client => {
        if (client.id === clientId) {
          console.log('Cambiando estado del cliente con ID:', clientId, 'de', client.estado);
          return { 
            ...client, 
            estado: client.estado === 'No Contactado' ? 'Contactado' : 'No Contactado' 
          };
        }
        return client;
      });
      console.log('Clientes después de conectar:', newClients);
      localStorage.setItem('clients', JSON.stringify(newClients));
      return newClients;
    });

    let url = '';
    if (ejecutivoActivo === 'nataly') {
  url = 'https://infoplan.daily.co/nataly?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoibmF0YWx5IiwibyI6dHJ1ZSwiZCI6IjllZDc4MzU0LWIzNjEtNGM5ZS1iNWYxLTA3MjQ0ZDIxNDg2MiIsImlhdCI6MTcyNTk1Mjc3MH0.ghUbakK0mocAgQ6p8foMWFe-xhTZ8o9JK6kQatesSi8';
} else if (ejecutivoActivo === 'pablo') {
  url = 'https://infoplan.daily.co/pablo?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicGFibG8iLCJvIjp0cnVlLCJkIjoiOWVkNzgzNTQtYjM2MS00YzllLWI1ZjEtMDcyNDRkMjE0ODYyIiwiaWF0IjoxNzI1OTUzMzA1fQ.xslSYDTgIXt3WbsW58XpN7lj0R6xpaIeFtJU4nsiYLA';
} else if (ejecutivoActivo === 'ruby') {
  url = 'https://infoplan.daily.co/ruby?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvIjp0cnVlLCJ1IjoiQWRtaW5pc3RyYXRvciIsInIiOiJydWJ5IiwiZCI6IjllZDc4MzU0LWIzNjEtNGM5ZS1iNWYxLTA3MjQ0ZDIxNDg2MiIsImlhdCI6MTcyNzkyNjY5M30.cpKZse0BCDvnGQhOY9HFrtesMPoafFPHKWKUpbPUa2k';
} else if (ejecutivoActivo === 'javi') {
  url = 'https://infoplan.daily.co/javi?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvIjp0cnVlLCJ1IjoiamF2aSIsInIiOiJqYXZpIiwiZCI6IjllZDc4MzU0LWIzNjEtNGM5ZS1iNWYxLTA3MjQ0ZDIxNDg2MiIsImlhdCI6MTcyNzkyODAzNH0.1UeGFwlR3EQqaUTURqMWpAJwMsrqAiLm1iAN08nMqdA';
}



    if (url) {
      window.open(url, '_blank');
    }
  };

  const filteredClients = clients.filter(client => {
    console.log('Filtrando cliente:', client);
    console.log('Username actual:', username);
    
    if (!client || typeof client.ejecutivo !== 'string') {
      console.log('Cliente inválido o ejecutivo no es string');
      return false;
    }

    const matchesExecutive = client.ejecutivo?.toLowerCase() === username.toLowerCase();
    console.log('¿Coincide con el ejecutivo?', matchesExecutive);

    return (
      matchesExecutive && (
        searchTerm === '' ||
        (client.name && typeof client.name === 'string' && client.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (client.phone && typeof client.phone === 'string' && client.phone.includes(searchTerm)) ||
        (client.posicion_cliente && typeof client.posicion_cliente === 'string' && client.posicion_cliente.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (client.isapre && typeof client.isapre === 'string' && client.isapre.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (client.plan && typeof client.plan === 'string' && client.plan.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    );
  });

  console.log('Clientes filtrados:', filteredClients);

  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);

  console.log('Clientes a mostrar en la página actual:', currentClients);

  const paginate = (pageNumber) => {
    console.log('Paginar hacia la página:', pageNumber);
    setCurrentPage(pageNumber);
  };

  const handleDragStart = (event, clientId) => {
    console.log('Iniciando arrastre de cliente con ID:', clientId);
    event.dataTransfer.setData("application/json", JSON.stringify({ clientId }));
    setDraggedClient(clientId);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    console.log('Elemento arrastrado sobre un área válida.');
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedData = event.dataTransfer.getData("application/json");
    console.log('Datos recibidos en el drop:', droppedData);
    
    try {
      const { clientId } = JSON.parse(droppedData);
      console.log('ID del cliente arrastrado:', clientId);
      
      if (clientId) {
        const client = clients.find(c => c.id === clientId);
        console.log('Cliente encontrado:', client);
        
        if (client) {
          setTasks(prevTasks => {
            const newTask = { 
              id: Date.now(), 
              clientName: client.name, 
              clientId: client.id,
              clientInfo: {
                rut: client.rut,
                email: client.email,
                phone: client.phone,
                codigo_plan: client.codigo_plan,
              },
              tasks: [''], 
              color: 'gray' 
            };
            console.log('Nueva tarea a añadir:', newTask);
            const newTasks = [...prevTasks, newTask];
            console.log('Nueva lista de tareas:', newTasks);
            localStorage.setItem('tasks', JSON.stringify(newTasks));
            return newTasks;
          });
        } else {
          console.error('No se encontró el cliente con ID:', clientId);
        }
      } else {
        console.error('No se recibió un ID de cliente válido');
      }
    } catch (error) {
      console.error('Error al procesar los datos arrastrados:', error);
    }
    
    setDraggedClient(null);
  };

  const handleTaskChange = (taskId, index, value) => {
    console.log(`Cambio en la tarea ${index} de la tarea con ID ${taskId}:`, value);
    setTasks(prevTasks => {
      const newTasks = prevTasks.map(task =>
        task.id === taskId
          ? { ...task, tasks: task.tasks.map((t, i) => i === index ? value : t) }
          : task
      );
      localStorage.setItem('tasks', JSON.stringify(newTasks));
      return newTasks;
    });
  };

  const handleAddSubtask = (taskId) => {
    console.log('Añadir sub-tarea para la tarea con ID:', taskId);
    setTasks(prevTasks => {
      const newTasks = prevTasks.map(task =>
        task.id === taskId
          ? { ...task, tasks: [...task.tasks, ''] }
          : task
      );
      localStorage.setItem('tasks', JSON.stringify(newTasks));
      return newTasks;
    });
  };

  const handleRemoveSubtask = (taskId, index) => {
    console.log(`Eliminar sub-tarea ${index} de la tarea con ID ${taskId}`);
    setTasks(prevTasks => {
      const newTasks = prevTasks.map(task =>
        task.id === taskId
          ? { ...task, tasks: task.tasks.filter((_, i) => i !== index) }
          : task
      );
      localStorage.setItem('tasks', JSON.stringify(newTasks));
      return newTasks;
    });
  };

  const handleColorChange = (taskId, color) => {
    console.log('Cambio de color en la tarea con ID:', taskId, 'Nuevo color:', color);
    setTasks(prevTasks => {
      const newTasks = prevTasks.map(task =>
        task.id === taskId ? { ...task, color } : task
      );
      localStorage.setItem('tasks', JSON.stringify(newTasks));
      return newTasks;
    });
  };

  const handleRemoveTask = (taskId) => {
    console.log('Eliminar tarea con ID:', taskId);
    setTasks(prevTasks => {
      const newTasks = prevTasks.filter(task => task.id !== taskId);
      localStorage.setItem('tasks', JSON.stringify(newTasks));
      return newTasks;
    });
  };

  const filteredTasks = tasks.filter(task =>
    task.clientName.toLowerCase().includes(taskSearchTerm.toLowerCase())
  );

  console.log('Tareas filtradas:', filteredTasks);

  const toggleClientExpansion = (clientId) => {
    console.log('Expandir o contraer cliente con ID:', clientId);
    setExpandedClients(prevState => ({
      ...prevState,
      [clientId]: !prevState[clientId]
    }));
  };

  const handleSortOrderChange = (order) => {
    console.log('Cambiando el orden a:', order);
    setSortOrder(order);
    setClients(prevClients => {
      const sortedClients = [...prevClients].sort((a, b) => {
        const dateA = new Date(a.timestamp || a.fecha);
        const dateB = new Date(b.timestamp || b.fecha);
        return order === 'recent' ? dateB - dateA : dateA - dateB;
      });
      return sortedClients;
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Fecha inválida';
    }
    return date.toLocaleString();
  };

  const refreshDashboard = async () => {
    console.log('Recargando datos del dashboard');
    try {
      const fetchedClients = await clientService.getClients(username);
      const sortedClients = fetchedClients.sort((a, b) => new Date(b.timestamp || b.fecha) - new Date(a.timestamp || a.fecha));
      setClients(sortedClients);
      console.log('Datos del dashboard recargados exitosamente');
    } catch (error) {
      console.error('Error al recargar los datos del dashboard:', error);
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="w-full max-w-md">
          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-6 text-center">
              <h2 className="text-2xl font-bold text-gray-700">Portal Ejecutivos</h2>
              <p className="text-gray-600">Ingresa tus credenciales para acceder</p>
            </div>
            <form onSubmit={handleLogin}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                  Usuario
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Usuario"
                  value={username}
                  onChange={(event) => {
                    console.log('Cambiando valor de username:', event.target.value);
                    setUsername(event.target.value);
                  }}
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                  Contraseña
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="******************"
                  value={password}
                  onChange={(event) => {
                    console.log('Cambiando valor de password:', event.target.value);
                    setPassword(event.target.value);
                  }}
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                  type="submit"
                >
                  Iniciar Sesión
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 flex flex-col">
      <h1 className="text-2xl font-bold mb-4">Dashboard de Ejecutivos</h1>
      {(isPlaying || isVideoAlertPlaying) && (
        <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded flex justify-between items-center">
          <span>{isVideoAlertPlaying ? '¡Solicitud de videollamada! Se requiere su atención.' : '¡Nuevo cliente! Se requiere su atención.'}</span>
          <button
            onClick={stopAlarm}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded"
          >
            Detener Alarma
          </button>
        </div>
      )}
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-3/5 md:pr-4 mb-4 md:mb-0">
          <input
            type="text"
            placeholder="Buscar por nombre, teléfono o código de plan"
            className="w-full p-2 mb-4 border rounded"
            value={searchTerm}
            onChange={(event) => {
              console.log('Cambiando valor de searchTerm:', event.target.value);
              setSearchTerm(event.target.value);
            }}
          />
          <div className="flex justify-between items-center mb-4">
            <span>Ordenar por fecha:</span>
            <select
              className="border rounded px-2 py-1"
              value={sortOrder}
              onChange={(e) => handleSortOrderChange(e.target.value)}
            >
              <option value="recent">Más reciente</option>
              <option value="oldest">Más antiguo</option>
            </select>
          </div>
          <div className="overflow-x-auto overflow-y-auto max-h-[calc(100vh-200px)] md:max-h-[calc(100vh-300px)]">
            <table className="min-w-full bg-white">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-xs leading-normal">
                  <th className="py-2 px-3 text-left">#</th>
                  <th className="py-2 px-3 text-left">Nombre</th>
                  <th className="py-2 px-3 text-left">Teléfono</th>
                  <th className="py-2 px-3 text-left">Fecha</th>
                  <th className="py-2 px-3 text-left">Acciones</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm">
                {currentClients.map((client, index) => (
                  <React.Fragment key={client.id}>
                    <tr 
                      className={`relative border-b border-gray-200 hover:bg-gray-100 ${index === 0 ? 'bg-[#bafdd2]' : ''}`}
                      draggable="true"
                      onDragStart={(event) => handleDragStart(event, client.id)}
                    >
                      <td className="py-2 px-3 text-left whitespace-nowrap">
                        <span className="font-medium">{indexOfFirstClient + index + 1}</span>
                      </td>
                      <td className="py-2 px-3 text-left whitespace-nowrap">
                        <span className="font-medium">{client.name}</span>
                      </td>
                      <td className="py-2 px-3 text-left">
                        <span>{client.phone}</span>
                      </td>
                      <td className="py-2 px-3 text-left">
                        <span>{formatDate(client.timestamp || client.fecha)}</span>
                      </td>
                      <td className="py-2 px-3 text-left">
                        <div className="flex items-center space-x-2">
                          <button
                            onClick={() => toggleClientExpansion(client.id)}
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded text-xs"
                          >
                            {expandedClients[client.id] ? 'Ocultar' : 'Ver'}
                          </button>
                          <button
                            onClick={() => handleConnect(client.id)}
                            className={`font-bold py-1 px-2 rounded text-xs ${
                              client.estado === 'No Contactado'
                                ? 'bg-green-500 hover:bg-green-600 text-white'
                                : 'bg-gray-500 hover:bg-gray-600 text-white'
                            }`}
                          >
                            {client.estado === 'No Contactado' ? 'Conectar' : 'Contactado'}
                          </button>
                        </div>
                      </td>
                    </tr>
                    {expandedClients[client.id] && (
                      <tr>
                        <td colSpan="5" className="py-2 px-3">
                          <div className="mt-2 p-2 bg-white shadow rounded text-xs">
                            <p><strong>Nombre:</strong> {client.name}</p>
                            <p><strong>RUT:</strong> {client.rut}</p>
                            <p><strong>Email:</strong> {client.email}</p>
                            <p><strong>Teléfono:</strong> {client.phone}</p>
                            <p><strong>Ejecutivo:</strong> {client.ejecutivo}</p>
                            <p><strong>Posición Cliente:</strong> {client.posicion_cliente}</p>
                            <p><strong>Código de Plan:</strong> {client.codigo_plan}</p>
                            <p><strong>Isapre:</strong> {client.isapre}</p>
                            <p><strong>Plan:</strong> {client.plan}</p>
                            <p><strong>Precio Total CLP:</strong> {client.precio_total_clp}</p>
                            <p><strong>Tipo de Plan:</strong> {client.tipo_de_plan}</p>
                            <p><strong>Nota:</strong> {client.nota}</p>
                            <p><strong>Precio Mínimo CLP:</strong> {client.precio_minimo_clp}</p>
                            <p><strong>Cargas:</strong> {client.cargas}</p>
                            <p><strong>Edad Cotizante 1:</strong> {client.edad_cotizante1}</p>
                            <p><strong>Edad Cotizante 2:</strong> {client.edad_cotizante2 || 'N/A'}</p>
                            <p><strong>Edades Cargas:</strong> {client.edades_cargas || 'N/A'}</p>
                            <p><strong>Región:</strong> {client.region}</p>
                            <p><strong>Fecha:</strong> {formatDate(client.timestamp || client.fecha)}</p>
                            <p><strong>Tarea 1:</strong> {client.tarea1 || 'N/A'}</p>
                            <p><strong>Estado:</strong> {client.estado}</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center mt-4">
            {[...Array(Math.ceil(filteredClients.length / clientsPerPage))].map((_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`mx-1 px-3 py-1 border rounded ${
                  currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-white'
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
        <div className="w-full md:w-2/5 md:pl-4">
          <div 
            className="border-2 border-dashed border-gray-300 p-4 h-full overflow-y-auto max-h-[calc(100vh-200px)] md:max-h-[calc(100vh-100px)]"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <h2 className="text-xl font-bold mb-4">Tareas</h2>
            <input
              type="text"
              placeholder="Buscar tareas por nombre de cliente"
              className="w-full p-2 mb-4 border rounded"
              value={taskSearchTerm}
              onChange={(event) => {
                console.log('Cambiando valor de taskSearchTerm:', event.target.value);
                setTaskSearchTerm(event.target.value);
              }}
            />
            {filteredTasks.map((task, taskIndex) => (
              <div key={task.id} className={`bg-${task.color === 'yellow' ? 'yellow-400' : `${task.color}-500`} bg-opacity-20 p-2 mb-2 rounded relative`}>
                <button 
                  onClick={() => handleRemoveTask(task.id)}
                  className="absolute top-1 right-1 text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
                <div className="flex items-center space-x-2 mb-2">
                  <span className="font-bold text-sm">{taskIndex + 1}. {task.clientName}</span>
                  <ColorSelector
                    selectedColor={task.color}
                    onColorChange={(color) => handleColorChange(task.id, color)}
                  />
                </div>
                {task.tasks.map((subtask, index) => (
                  <div key={index} className={`mb-1 ${index === 0 ? `bg-${task.color === 'yellow' ? 'yellow-400' : `${task.color}-500`} bg-opacity-30` : `bg-${task.color === 'yellow' ? 'yellow-400' : `${task.color}-500`} bg-opacity-20`} p-1 rounded`}>
                    <div className="flex items-center">
                      <textarea
                        value={subtask}
                        onChange={(e) => handleTaskChange(task.id, index, e.target.value)}
                        placeholder={`Tarea ${index + 1}`}
                        className={`flex-grow p-1 rounded resize-none overflow-hidden bg-${task.color === 'yellow' ? 'yellow-400' : `${task.color}-500`} bg-opacity-10 text-xs`}
                        style={{
                          minHeight: '1.5em',
                          height: 'auto',
                        }}
                        onInput={(e) => {
                          e.target.style.height = 'auto';
                          e.target.style.height = e.target.scrollHeight + 'px';
                        }}
                      />
                      {index === 0 ? (
                        <button
                          onClick={() => handleAddSubtask(task.id)}
                          className="ml-2 text-blue-500 hover:text-blue-700 text-xs"
                        >
                          +
                        </button>
                      ) : (
                        <button
                          onClick={() => handleRemoveSubtask(task.id, index)}
                          className="ml-2 text-red-500 hover:text-red-700 text-xs"
                        >
                          -
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="fixed top-0 right-0 m-4 flex space-x-2">
        <button className="text-white bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded" onClick={refreshDashboard}>
          Recargar
        </button>
        <button className="text-white bg-red-600 hover:bg-red-700 font-bold py-2 px-4 rounded" onClick={onClose}>
          X
        </button>
      </div>
    </div>
  );
};

export default ExecutiveDashboard;