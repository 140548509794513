import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useMediaQuery } from 'react-responsive';

// Importar logos
import BanmedicaLogo from '../assets/Banmedica.webp';
import ColmenaLogo from '../assets/Colmena.webp';
import EsencialLogo from '../assets/Esencial.webp';
import NuevaMasvidaLogo from '../assets/NuevaMasvida.webp';
import VidatresLogo from '../assets/Vidatres.webp';
import ConsaludLogo from '../assets/Consalud.webp';
import PlanDetailModal from './PlanDetailModal';

// Función para escapar caracteres especiales en expresiones regulares
const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const formatPrice = (price) => {
  if (!price) return 'Precio no especificado';
  return `$ ${Number(price).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
};

const HealthPlanCard = ({ plan, selectedClinic, region, edad_cotizante1, edad_cotizante2, cargas, precio_minimo_clp, ejecutivoActivo, onCardSelect, isSelected }) => {
  const regionSeleccionada = region;

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [displayInfo, setDisplayInfo] = useState({
    prestadores: '',
    coberturaHospitalaria: '',
    coberturaAmbulatoria: '',
    coberturaUrgencia: ''
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialClickPos, setInitialClickPos] = useState({ x: 0, y: 0 });
  const [activeTab, setActiveTab] = useState('general');
  const cardRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState({ prestadores: false, hospitalaria: false, ambulatoria: false });
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const prestadoresRef = useRef(null);
  const hospitalariaRef = useRef(null);
  const ambulatoriaRef = useRef(null);

  const getIsapreLogo = (isapreName) => {
    switch (isapreName.toLowerCase()) {
      case 'banmedica':
        return BanmedicaLogo;
      case 'colmena':
        return ColmenaLogo;
      case 'esencial':
        return EsencialLogo;
      case 'nueva masvida':
        return NuevaMasvidaLogo;
      case 'vidatres':
        return VidatresLogo;
      case 'consalud':
        return ConsaludLogo;
      default:
        return null;
    }
  };


  const processUrgencyInfo = useCallback((urgencyCoverage) => {
    if (!urgencyCoverage || urgencyCoverage.trim() === '') {
      return 'Libre Elección';
    }

    if (selectedClinic) {
      const clinicRegex = new RegExp(`${escapeRegExp(selectedClinic)}\\s*:\\s*(\\d+%|Copago Fijo \\d+(\\.\\d+)?UF)`);
      const clinicMatch = urgencyCoverage.match(clinicRegex);
      if (clinicMatch) {
        return clinicMatch[1];
      } else {
        return 'Libre Elección';
      }
    }

    const generalMatch = urgencyCoverage.match(/(Copago Fijo \d+(\.\d+)?UF|\d+%)/);
    if (generalMatch) {
      return generalMatch[1];
    }

    const libreEleccionMatch = urgencyCoverage.match(/Libre Elección \((\d+)%\)/);
    if (libreEleccionMatch) {
      return `Libre Elección (${libreEleccionMatch[1]}%)`;
    }

    return 'Libre Elección';
  }, [selectedClinic]);

  const getRegionPriorities = useMemo(() => (region) => {
    const priorities = {
      Norte: [
        { name: 'Clínica San José de Arica', priority: 1 },
        { name: 'Clínica Dávila', priority: 2 },
        { name: 'Clínica Dávila Vespucio', priority: 3 },
        { name: 'Clínica Hospital del Profesor', priority: 4 },
        { name: 'Clínica Santa María', priority: 5 }
      ],
      Quinta: [
        { name: 'Clínica Reñaca', priority: 1 },
        { name: 'Clínica Bupa Reñaca', priority: 2 },
        { name: 'Clínica RedSalud Valparaíso', priority: 3 },
        { name: 'Hospital Clínico Viña del Mar', priority: 4 },
        { name: 'Clínica Ciudad del Mar', priority: 5 }
      ],
      Centro: [
        { name: 'Clínica Fusat', priority: 1 },
        { name: 'Clínica RedSalud Rancagua', priority: 2 },
        { name: 'Clínica Lircay de Talca', priority: 3 },
        { name: 'Centros RedSalud', priority: 4 }
      ],
      Octava: [
        { name: 'Clínica Sanatorio Alemán', priority: 1 },
        { name: 'Clínica Biobío', priority: 2 },
        { name: 'Clínica Andes Salud Concepción', priority: 3 },
        { name: 'Clínica Los Andes de Los Ángeles', priority: 4 },
        { name: 'Hospital Clínico del Sur', priority: 5 }
      ],
      Sur: [
        { name: 'Clínica Alemana de Temuco', priority: 1 },
        { name: 'Clínica Alemana de Valdivia', priority: 2 },
        { name: 'Clínica Andes Salud Puerto Montt', priority: 3 },
        { name: 'Clínica RedSalud Mayor de Temuco', priority: 4 },
        { name: 'Clínica Puerto Montt', priority: 5 },
        { name: 'Clínica Puerto Varas', priority: 6 },
        { name: 'Clínica Universitaria de Puerto Montt', priority: 7 },
        { name: 'Clínica RedSalud Magallanes', priority: 8 }
      ]
    };

    return priorities[region] || [];
  }, []);

  const getGeneralPriorities = useMemo(() => () => {
    return [
      { name: 'Clínica Alemana', priority: 1 },
      { name: 'Clínica Las Condes', priority: 2 },
      { name: 'Clínica San Carlos de Apoquindo', priority: 3 },
      { name: 'Clínica Dávila', priority: 4 },
      { name: 'Clínica Universidad de Los Andes', priority: 5 },
      { name: 'Clínica Santa María', priority: 6 },
      { name: 'Hospital Clínico UC', priority: 7 },
      { name: 'Clínica RedSalud Providencia', priority: 8 },
      { name: 'Clínica Bupa Santiago', priority: 9 },
      { name: 'Clínica Indisa', priority: 10 },
      { name: 'Clínica RedSalud Santiago', priority: 11 },
      { name: 'Clínica RedSalud Vitacura', priority: 12 },
      { name: 'Clínica Dávila Vespucio', priority: 13 },
      { name: 'Centros RedSalud', priority: 14 },
      { name: 'Hospital Clínico Universidad de Chile', priority: 15 },
      { name: 'Clínica Cordillera', priority: 16 },
      { name: 'Clínica Meds', priority: 17 },
      { name: 'Centros Médicos Red UC', priority: 18 },
      { name: 'Centros Médicos Red Dávila', priority: 19 },
      { name: 'Clínica Hospital del Profesor', priority: 20 },
      { name: 'Vidaintegra', priority: 21 },
      { name: 'Integramédica', priority: 22 }
    ];
  }, []);

  const selectBestPrestador = useCallback((prestadores, coverages, priorities) => {
    const prestadoresWithCoverage = prestadores.map(prestador => {
      const coverage = coverages.find(c => c.prestador === prestador);
      const priority = priorities.find(p => prestador.includes(p.name));
      return {
        name: prestador,
        coverage: coverage ? coverage.coverage : 0,
        priority: priority ? priority.priority : Infinity
      };
    });

    prestadoresWithCoverage.sort((a, b) => {
      if (b.coverage !== a.coverage) return b.coverage - a.coverage;
      return a.priority - b.priority;
    });

    return prestadoresWithCoverage[0];
  }, []);

  const findRepeatingClinics = useCallback((coverages) => {
    const maxCoverage = Math.max(...coverages.map(c => c.coverage));
    const repeatingClinics = coverages.filter(c => c.coverage === maxCoverage);
    
    if (repeatingClinics.length > 1) {
      repeatingClinics.forEach(clinic => {
        // Aquí puedes agregar lógica adicional si es necesario
      });
    }
  }, []);

  const updateDisplayInfo = useCallback(() => {
    if (!plan) return;

    const newDisplayInfo = {
      prestadores: 'No especificados',
      coberturaHospitalaria: 'No especificada',
      coberturaAmbulatoria: 'No especificada',
      coberturaUrgencia: processUrgencyInfo(plan.urgencyCoverage)
    };

    if (plan.type === 'Libre Eleccion') {
      newDisplayInfo.prestadores = 'Libre Elección';
      newDisplayInfo.coberturaHospitalaria = `Libre Elección ${plan.freeChoiceHospital}%`;
      newDisplayInfo.coberturaAmbulatoria = `Libre Elección ${plan.freeChoiceAmbulatory}%`;
    } else {
      const prestadoresList = plan.prestadores ? plan.prestadores.split('\n').map(p => p.trim()) : [];

      if (selectedClinic && prestadoresList.some(p => p.includes(selectedClinic))) {
        newDisplayInfo.prestadores = `${selectedClinic} + otros`;

        const regexHosp = new RegExp(`(\\d+)%\\s+${escapeRegExp(selectedClinic)}`);
        const regexAmb = new RegExp(`(\\d+)%\\s+${escapeRegExp(selectedClinic)}`);

        newDisplayInfo.coberturaHospitalaria = plan.hospitalCoverage?.match(regexHosp)?.[1] + '%' || 
          (plan.freeChoiceHospital ? plan.freeChoiceHospital + '% (Libre Elección)' : 'No especificada');
        
        const ambulatoriaCoverage = plan.ambulatoryCoverage?.match(regexAmb)?.[1];
        newDisplayInfo.coberturaAmbulatoria = ambulatoriaCoverage 
          ? ambulatoriaCoverage + '%'
          : (plan.freeChoiceAmbulatory ? `Libre Elección ${plan.freeChoiceAmbulatory}%` : 'No especificada');
      } else {
        const regionPriorities = getRegionPriorities(regionSeleccionada);
        const generalPriorities = getGeneralPriorities();
        const allPriorities = [...regionPriorities, ...generalPriorities];

        const hospitalCoverages = prestadoresList.map(prestador => {
          const match = plan.hospitalCoverage?.match(new RegExp(`(\\d+)%\\s+${escapeRegExp(prestador)}`));
          return { prestador, coverage: match ? parseInt(match[1]) : 0 };
        });

        findRepeatingClinics(hospitalCoverages);

        const bestPrestador = selectBestPrestador(prestadoresList, hospitalCoverages, allPriorities);

        if (bestPrestador) {
          newDisplayInfo.prestadores = `${bestPrestador.name} + otros`;
          newDisplayInfo.coberturaHospitalaria = `${bestPrestador.coverage}%`;

          const regexAmb = new RegExp(`(\\d+)%\\s+${escapeRegExp(bestPrestador.name)}`);
          const ambulatoriaCoverage = plan.ambulatoryCoverage?.match(regexAmb)?.[1];
          newDisplayInfo.coberturaAmbulatoria = ambulatoriaCoverage 
            ? ambulatoriaCoverage + '%'
            : (plan.freeChoiceAmbulatory ? `Libre Elección ${plan.freeChoiceAmbulatory}%` : 'No especificada');
        } else {
          newDisplayInfo.prestadores = prestadoresList.join(', ') || 'No especificados';
          newDisplayInfo.coberturaHospitalaria = plan.freeChoiceHospital ? plan.freeChoiceHospital + '% (Libre Elección)' : 'No especificada';
          newDisplayInfo.coberturaAmbulatoria = plan.freeChoiceAmbulatory ? `Libre Elección ${plan.freeChoiceAmbulatory}%` : 'No especificada';
        }
      }
    }

    newDisplayInfo.coberturaUrgencia = processUrgencyInfo(plan.urgencyCoverage);

    setDisplayInfo(newDisplayInfo);
  }, [plan, selectedClinic, regionSeleccionada, processUrgencyInfo, getRegionPriorities, getGeneralPriorities, findRepeatingClinics, selectBestPrestador]);

  useEffect(() => {
    updateDisplayInfo();
  }, [updateDisplayInfo]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (isModalOpen) {
        setShowTooltip({
          prestadores: false,
          hospitalaria: false,
          ambulatoria: false
        });
        return;
      }

      if (prestadoresRef.current || hospitalariaRef.current || ambulatoriaRef.current) {
        const prestadoresRect = prestadoresRef.current?.getBoundingClientRect();
        const hospitalariaRect = hospitalariaRef.current?.getBoundingClientRect();
        const ambulatoriaRect = ambulatoriaRef.current?.getBoundingClientRect();

        const isHoveringPrestadores = prestadoresRect &&
          event.clientX >= prestadoresRect.left &&
          event.clientX <= prestadoresRect.right &&
          event.clientY >= prestadoresRect.top &&
          event.clientY <= prestadoresRect.bottom;
        
        const isHoveringHospitalaria = hospitalariaRect &&
          event.clientX >= hospitalariaRect.left &&
          event.clientX <= hospitalariaRect.right &&
          event.clientY >= hospitalariaRect.top &&
          event.clientY <= hospitalariaRect.bottom;
        
        const isHoveringAmbulatoria = ambulatoriaRect &&
          event.clientX >= ambulatoriaRect.left &&
          event.clientX <= ambulatoriaRect.right &&
          event.clientY >= ambulatoriaRect.top &&
          event.clientY <= ambulatoriaRect.bottom;

        setShowTooltip({
          prestadores: isHoveringPrestadores,
          hospitalaria: isHoveringHospitalaria,
          ambulatoria: isHoveringAmbulatoria
        });

        if (isHoveringPrestadores) {
          setTooltipPosition({
            top: prestadoresRect.top - 10,
            left: prestadoresRect.left
          });
        } else if (isHoveringHospitalaria) {
          setTooltipPosition({
            top: hospitalariaRect.top - 10,
            left: hospitalariaRect.left
          });
        } else if (isHoveringAmbulatoria) {
          setTooltipPosition({
            top: ambulatoriaRect.top - 10,
            left: ambulatoriaRect.left
          });
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isModalOpen]);

  const isapreLogo = getIsapreLogo(plan.isapre.name);

  const Tooltip = ({ type }) => {
    if (isModalOpen || !showTooltip[type]) return null;

    let content;
    switch (type) {
      case 'prestadores':
        content = plan.type === 'Libre Eleccion' ? 'Libre Elección' : (plan.prestadores ? plan.prestadores.split('\n').map((line, index) => (
          <div key={index}>{line.trim()}</div>
        )) : 'No hay prestadores especificados');
        break;
      case 'hospitalaria':
        content = plan.type === 'Libre Eleccion' ? `Libre Elección ${plan.freeChoiceHospital}%` : (plan.hospitalCoverage ? plan.hospitalCoverage.split('\n').map((line, index) => (
          <div key={index}>{line.trim()}</div>
        )) : 'No hay cobertura hospitalaria especificada');
        break;
      case 'ambulatoria':
        content = plan.type === 'Libre Eleccion' ? `Libre Elección ${plan.freeChoiceAmbulatory}%` : (plan.ambulatoryCoverage ? plan.ambulatoryCoverage.split('\n').map((line, index) => (
          <div key={index}>{line.trim()}</div>
        )) : 'No hay cobertura ambulatoria especificada');
        break;
      default:
        content = 'No hay información disponible';
    }

    return ReactDOM.createPortal(
      <div
        style={{
          position: 'fixed',
          top: `${tooltipPosition.top}px`,
          left: `${tooltipPosition.left}px`,
          zIndex: 9999,
          backgroundColor: 'white',
          border: '1px solid #e2e8f0',
          borderRadius: '0.25rem',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          maxWidth: '300px'
        }}
      >
        <div
          style={{
            backgroundColor: '#e5e7eb',
            fontWeight: 'bold',
            color: '#2f3336', 
            fontSize: '1.1em',
            padding: '0.25rem',
            borderBottom: '1px solid #e2e8f0',
          }}
        >
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </div>
        <div style={{ backgroundColor: 'white', padding: '0.5rem' }}>
          {content}
        </div>
      </div>,
      document.body
    );
  };

  const handleMouseDown = (event) => {
    setInitialClickPos({ x: event.clientX, y: event.clientY });
  };

  const handleCardSelect = () => {
    if (typeof onCardSelect === 'function') {
      onCardSelect(plan.id);
    }
  };

  const handleSolicitar = (event) => {
    const { x, y } = initialClickPos;
    if (Math.abs(x - event.clientX) < 5 && Math.abs(y - event.clientY) < 5) {
      setActiveTab('request');
      setIsModalOpen(true);
      handleCardSelect();
    }
  };

  const handleVerDetalle = (event) => {
    const { x, y } = initialClickPos;
    if (Math.abs(x - event.clientX) < 5 && Math.abs(y - event.clientY) < 5) {
      setActiveTab('general');
      setIsModalOpen(true);
      handleCardSelect();
    }
  };

  const handleAtencionEnVivo = (event) => {
    const { x, y } = initialClickPos;
    if (Math.abs(x - event.clientX) < 5 && Math.abs(y - event.clientY) < 5) {
      setActiveTab('videocall');
      setIsModalOpen(true);
      handleCardSelect();
    }
  };

  const handleFormSubmit = (formData) => {
    // Aquí puedes agregar la lógica para manejar el envío del formulario
  };

  const cardClassName = `bg-white rounded-lg shadow-md p-4 w-full relative ${
    isSelected ? 'border-[#22c55e] border-4' : 'border border-gray-200'
  }`;


  const showPriceMessage = precio_minimo_clp === 0 && edad_cotizante1 === undefined;

const PriceMessage = () => (
  <span className="bg-[#ffdada] text-[#ea052a] text-xs font-semibold px-2.5 py-0.5 rounded ml-2">
    ¡Completa el formulario  de arriba para ver el precio exacto!
  </span>
);

useEffect(() => {
  console.log('El valor de la región es:', edad_cotizante1);
}, [edad_cotizante1]);


  const MobileCard = () => (
    <div 
      ref={cardRef}
      className={cardClassName}
    >
      <div className="flex items-center justify-between mb-2">
        <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded">
          {plan.type || 'Tipo no especificado'}
        </span>
         {showPriceMessage && <PriceMessage />}
        <div className="flex items-center bg-[#3b82f6] text-white text-xs font-bold px-2 py-1 rounded">
          <span className="mr-1">★</span>
          {plan.score ? `${plan.score} de 7` : 'N/E'}
        </div>
      </div>
      <div className="flex flex-col items-start mb-2">
        {isapreLogo && (
          <img 
            src={isapreLogo} 
            alt={plan.isapre.name} 
            className="w-[7rem] h-[7rem] object-contain mb-1" 
          />
        )}
        <div className="text-sm font-bold">{plan.name || 'Nombre del plan no especificado'}</div>
      </div>
      <div className="grid grid-cols-2 gap-2 text-sm mb-2">
        <div className="relative">
          <div className="font-semibold">Prestadores</div>
          <span 
            ref={prestadoresRef}
            className="text-gray-600 cursor-help"
          >
            {displayInfo.prestadores}
          </span>
          <Tooltip type="prestadores" />
        </div>
        <div className="relative">
          <div className="font-semibold">Hospitalaria</div>
          <span 
            ref={hospitalariaRef}
            className="text-gray-600 cursor-help"
          >
            {displayInfo.coberturaHospitalaria}
          </span>
          <Tooltip type="hospitalaria" />
        </div>
        <div className="relative">
          <div className="font-semibold">Ambulatoria</div>
          <span 
            ref={ambulatoriaRef}
            className="text-gray-600 cursor-help"
          >
            {displayInfo.coberturaAmbulatoria}
          </span>
          <Tooltip type="ambulatoria" />
        </div>
        <div>
          <div className="font-semibold">Urgencia</div>
          <span className="text-gray-600">{displayInfo.coberturaUrgencia}</span>
        </div>
        <div>
          <div className="font-semibold">Tope Anual</div>
          <span className="text-gray-600">{plan.annualLimit || 'No especificado'}</span>
        </div>
          {/* Nueva sección de Beneficios Gratis para Nueva Masvida */}
          {plan.isapre.name.toLowerCase() === "nueva masvida" && (
            <div>
              <div className="text-sm font-semibold text-blue-600">Beneficios Gratis</div>
              <span className="text-sm text-gray-600">
                Urgencia costo 0
                <br />
                (4 eventos anuales)
              </span>
            </div>
          )}
      </div>
      <div style={{ fontSize: '170%' }} className="font-bold mb-2 text-center md:text-left">
  {plan.price ? formatPrice(plan.price) : 'Precio no especificado'}
</div>

      <button 
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mb-1 w-full"
        onMouseDown={handleMouseDown}
        onMouseUp={handleSolicitar}
      >
        Solicitar
      </button>
      <button 
        className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded w-full mb-1"
        onMouseDown={handleMouseDown}
        onMouseUp={handleVerDetalle}
      >
        Ver Detalle
      </button>
      <button 
        className="bg-[#22c55e] hover:bg-[#1ea34b] text-white font-bold py-2 px-4 rounded w-full mx-auto border border-[#0a4e23]"
        onMouseDown={handleMouseDown}
        onMouseUp={handleAtencionEnVivo}
      >
        Atención en vivo por Video
      </button>
    </div>
  );

  const DesktopCard = () => (
    <div 
      ref={cardRef}
      className={cardClassName}
    >
      <div className="absolute top-2 left-2">
        <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded">
          {plan.type || 'Tipo no especificado'}
        </span>
          {showPriceMessage && <PriceMessage />}
      </div>
      <div className="flex justify-between items-start mb-2 mt-4">
        <div className="flex flex-col w-[70%] items-start border-r border-gray-300 pr-4">
          <div className="flex items-center mb-2">
            {isapreLogo && (
              <img src={isapreLogo} alt={plan.isapre.name} className="w-20 h-20 object-contain mr-3" />
            )}
            <div>
              <div className="text-base font-bold">{plan.isapre.name || 'Isapre no especificada'}</div>
              <div className="text-sm">{plan.name || 'Nombre del plan no especificado'}</div>
              <div className="text-xs text-gray-500">Código: {plan.code || 'No especificado'}</div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-2 w-full mt-2 border-t border-gray-300 pt-2">
            <div className="relative">
              <div className="text-sm font-semibold">Prestadores</div>
              <span 
                ref={prestadoresRef}
                className="text-sm text-gray-600 cursor-help"
              >
                {displayInfo.prestadores}
              </span>
              <Tooltip type="prestadores" />
            </div>
            <div className="relative">
              <div className="text-sm font-semibold">Hospitalaria</div>
              <span 
                ref={hospitalariaRef}
                className="text-sm text-gray-600 cursor-help"
              >
                {displayInfo.coberturaHospitalaria}
              </span>
              <Tooltip type="hospitalaria" />
            </div>
            <div className="relative">
              <div className="text-sm font-semibold">Ambulatoria</div>
              <span 
                ref={ambulatoriaRef}
                className="text-sm text-gray-600 cursor-help"
              >
                {displayInfo.coberturaAmbulatoria}
              </span>
              <Tooltip type="ambulatoria" />
            </div>
            <div>
              <div className="text-sm font-semibold">Urgencia</div>
              <span className="text-sm text-gray-600">{displayInfo.coberturaUrgencia}</span>
            </div>
            <div>
              <div className="text-sm font-semibold">Tope Anual</div>
              <span className="text-sm text-gray-600">{plan.annualLimit || 'No especificado'}</span>
            </div>
              {/* Nueva sección de Beneficios Gratis para Nueva Masvida */}
          {plan.isapre.name.toLowerCase() === "nueva masvida" && (
            <div>
              <div className="text-sm font-semibold text-blue-600">Beneficios Gratis</div>
              <span className="text-sm text-gray-600">
                Urgencia costo 0
                <br />
                (4 eventos anuales)
              </span>
            </div>
          )}
          </div>
        </div>
        <div className="flex flex-col w-[30%] items-center justify-center">
          <div style={{ fontSize: '160%' }} className="font-bold mb-2">{plan.price ? formatPrice(plan.price) : 'Precio no especificado'}</div>
          <button 
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mb-2 w-full text-sm"
            onMouseDown={handleMouseDown}
            onMouseUp={handleSolicitar}
          >
            Solicitar
          </button>
          <button 
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded w-full text-sm mb-2"
            onMouseDown={handleMouseDown}
            onMouseUp={handleVerDetalle}
          >
            Ver Detalle
          </button>
          <button 
            className="bg-[#22c55e] hover:bg-[#1ea34b] text-white font-bold py-2 px-4 rounded w-full text-sm border border-[#0a4e23]"
            onMouseDown={handleMouseDown}
            onMouseUp={handleAtencionEnVivo}
          >
            Atención en vivo por Video
          </button>
        </div>
      </div>
      <div className="absolute top-2 right-2">
        <div className="flex items-center bg-[#3b82f6] text-white text-sm font-bold px-2 py-1 rounded">
          <span className="mr-1">★</span>
          {plan.score ? `${plan.score} de 7` : 'N/E'}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? <MobileCard /> : <DesktopCard />}
      <PlanDetailModal 
        isOpen={isModalOpen} 
        onClose={() => {
          setIsModalOpen(false);
          if (typeof onCardSelect === 'function') {
            onCardSelect(null);
          }
        }} 
        planDetails={{
          plan: plan.name,
          codigo_plan: plan.code,
          isapre: plan.isapre.name,
          nota: plan.score,
          precio_total_clp: plan.price,
          precio_minimo_clp: precio_minimo_clp,
          cargas: cargas,
          edad_cotizante1: edad_cotizante1,
          edad_cotizante2: edad_cotizante2,
          tipo_de_plan: plan.type,
          cobertura_hospitalaria: plan.hospitalCoverage,
          cobertura_ambulatoria: plan.ambulatoryCoverage,
          cobertura_urgencia: plan.urgencyCoverage,
          tope_anual: plan.annualLimit,
          cobertura_libre_eleccion_hospitalaria: plan.freeChoiceHospital,
          cobertura_libre_eleccion_ambulatoria: plan.freeChoiceAmbulatory,
          prestadores: plan.prestadores,
          clinica_seleccionada: selectedClinic,
          ejecutivoActivo: ejecutivoActivo
        }} 
        activeTab={activeTab}
        onSubmit={handleFormSubmit}
      />
    </>
  );
};

export default HealthPlanCard;