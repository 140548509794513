import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://infoplan.cl:4000/api';

const clientService = {
  // Aseg�rate de usar la funci�n correcta: getClients
  getClients: async (username) => {
    console.log(`Iniciando solicitud de clientes para el ejecutivo: ${username}`);
    try {
      const response = await axios.get(`${API_URL}/clientes?ejecutivo=${username}`);
      console.log('Respuesta recibida del servidor:', response.data); // Log para mostrar los clientes recibidos
      return response.data;
    } catch (error) {
      console.error('Error al solicitar los clientes:', error.message);
      if (error.response) {
        console.error('Datos de error:', error.response.data);
        console.error('C�digo de estado:', error.response.status);
      }
      throw error;
    }
  },
};

export default clientService;
