import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DaySelector = ({ selectedDays, onDayChange }) => {
  const days = ['L', 'M', 'X', 'J', 'V'];
  
  return (
    <div className="flex space-x-1">
      {days.map((day, index) => (
        <button
          key={day}
          className={`w-6 h-6 rounded-full ${selectedDays.includes(index) ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => onDayChange(index)}
        >
          {day}
        </button>
      ))}
    </div>
  );
};

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === 'pablo' && password === 'pablol44') {
      localStorage.setItem('isLoggedIn', 'true');
      onLogin();
    } else {
      alert('Credenciales incorrectas');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
        <h3 className="text-2xl font-bold text-center">Login al Dashboard</h3>
        <form onSubmit={handleSubmit}>
          <div className="mt-4">
            <div>
              <label className="block" htmlFor="username">Usuario</label>
              <input
                type="text"
                placeholder="Usuario"
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="mt-4">
              <label className="block" htmlFor="password">Contraseña</label>
              <input
                type="password"
                placeholder="Contraseña"
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="flex items-baseline justify-between">
              <button className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900" type="submit">Iniciar sesión</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const DashboardAdmin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [executives, setExecutives] = useState([
    { id: 1, nombre: 'nataly', telefono: '+56950973973', isapre: 'NuevaMasvida', activo: false },
    { id: 2, nombre: 'pablo', telefono: '+56945120235', isapre: 'Colmena', activo: false },
    { id: 3, nombre: 'ruby', telefono: '+56981218209', isapre: 'Colmena', activo: false },
    { id: 4, nombre: 'javi', telefono: '+56999059774', isapre: 'Colmena', activo: false }
  ]);
  const [tasks, setTasks] = useState([]);
  const [newExecutive, setNewExecutive] = useState({ nombre: '', telefono: '', isapre: '' });
  const [showNewExecutiveForm, setShowNewExecutiveForm] = useState(false);
  const [draggedExecutive, setDraggedExecutive] = useState(null);
  const [activeExecutive, setActiveExecutive] = useState(null);

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);
  }, []);

  useEffect(() => {
    const currentTime = new Date();
    setTasks(prevTasks => {
      const updatedTasks = prevTasks.map(task => {
        const startTime = new Date(task.startTime);
        const endTime = new Date(task.endTime);
        if (currentTime >= startTime && currentTime < endTime) {
          setExecutives(prevExecutives => 
            prevExecutives.map(exec => 
              exec.id === task.executiveId ? { ...exec, activo: true } : exec
            )
          );
          setActiveExecutive(task.executiveName);
        } else if (currentTime >= endTime) {
          setExecutives(prevExecutives => 
            prevExecutives.map(exec => 
              exec.id === task.executiveId ? { ...exec, activo: false } : exec
            )
          );
          setActiveExecutive(null);
          return prevTasks.filter(t => t.id !== task.id);
        }
        return task;
      });
      return updatedTasks;
    });
  }, []);

  useEffect(() => {
    if (activeExecutive && typeof activeExecutive === 'string' && activeExecutive.trim().length > 0) {
      const executiveLowerCase = activeExecutive.toLowerCase();
      axios.post('https://infoplan.cl:4000/api/actualizar-estado-ejecutivo', { ejecutivo: executiveLowerCase, nuevoEstado: 'activado' })
        .then(response => console.log('Active executive sent to /api/actualizar-estado-ejecutivo:', response.data))
        .catch(error => console.error('Error sending active executive:', error));
    }
  }, [activeExecutive]);

  const handleNewExecutive = () => {
    if (newExecutive.nombre && newExecutive.telefono && newExecutive.isapre) {
      setExecutives([...executives, { ...newExecutive, id: Date.now(), activo: false }]);
      setNewExecutive({ nombre: '', telefono: '', isapre: '' });
      setShowNewExecutiveForm(false);
    }
  };

  const handleToggleExecutive = (id) => {
    setExecutives(executives.map(exec => {
      if (exec.id === id) {
        const newStatus = !exec.activo;
        const nuevoEstado = newStatus ? 'activado' : 'desactivado';
        axios.post('https://infoplan.cl:4000/api/actualizar-estado-ejecutivo', { ejecutivo: exec.nombre, nuevoEstado: nuevoEstado })
          .then(response => console.log('Executive status updated:', response.data))
          .catch(error => console.error('Error updating executive status:', error));
        if (newStatus) {
          setActiveExecutive(exec.nombre);
        } else if (activeExecutive === exec.nombre) {
          setActiveExecutive(null);
        }
        return { ...exec, activo: newStatus };
      }
      return exec;
    }));
  };

  const handleDragStart = (event, executiveId) => {
    setDraggedExecutive(executiveId);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (draggedExecutive) {
      const executive = executives.find(e => e.id === draggedExecutive);
      setTasks([...tasks, { 
        id: Date.now(), 
        executiveId: executive.id, 
        executiveName: executive.nombre,
        startTime: new Date().toISOString(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000).toISOString(),
        days: [0, 1, 2, 3, 4] 
      }]);
      setDraggedExecutive(null);
    }
  };

  const handleTaskChange = (taskId, field, value) => {
    setTasks(tasks.map(task => 
      task.id === taskId ? { ...task, [field]: value } : task
    ));
  };

  const handleRemoveTask = (taskId) => {
    setTasks(tasks.filter(task => task.id !== taskId));
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    setIsLoggedIn(false);
  };

  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div className="container mx-auto p-4 flex flex-col relative">
      <button
        onClick={handleLogout}
        className="absolute top-4 right-20 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
      >
        Cerrar sesión
      </button>
      <button
        onClick={() => window.close()}
        className="absolute top-4 right-4 text-2xl font-bold text-gray-500 hover:text-gray-700"
      >
        ×
      </button>
      <h1 className="text-2xl font-bold mb-4">Dashboard de Administrador</h1>
      <div className="flex">
        <div className="w-3/5 pr-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Ejecutivos</h2>
            <button
              onClick={() => setShowNewExecutiveForm(!showNewExecutiveForm)}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              +
            </button>
          </div>
          {showNewExecutiveForm && (
            <div className="mb-4 p-4 bg-gray-100 rounded">
              <input
                type="text"
                placeholder="Nombre"
                value={newExecutive.nombre}
                onChange={(e) => setNewExecutive({...newExecutive, nombre: e.target.value})}
                className="w-full p-2 mb-2 border rounded"
              />
              <input
                type="text"
                placeholder="Teléfono"
                value={newExecutive.telefono}
                onChange={(e) => setNewExecutive({...newExecutive, telefono: e.target.value})}
                className="w-full p-2 mb-2 border rounded"
              />
              <input
                type="text"
                placeholder="Isapre"
                value={newExecutive.isapre}
                onChange={(e) => setNewExecutive({...newExecutive, isapre: e.target.value})}
                className="w-full p-2 mb-2 border rounded"
              />
              <button
                onClick={handleNewExecutive}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
              >
                Agregar Ejecutivo
              </button>
            </div>
          )}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Nombre</th>
                  <th className="py-3 px-6 text-left">Teléfono</th>
                  <th className="py-3 px-6 text-left">Isapre</th>
                  <th className="py-3 px-6 text-center">Estado</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {executives.map((executive) => (
                  <tr 
                    key={executive.id} 
                    className="border-b border-gray-200 hover:bg-gray-100"
                    draggable
                    onDragStart={(event) => handleDragStart(event, executive.id)}
                  >
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      <span className="font-medium">{executive.nombre}</span>
                    </td>
                    <td className="py-3 px-6 text-left">
                      <span>{executive.telefono}</span>
                    </td>
                    <td className="py-3 px-6 text-left">
                      <span>{executive.isapre}</span>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <button
                        onClick={() => handleToggleExecutive(executive.id)}
                        className={`py-1 px-3 rounded-full text-xs ${
                          executive.activo
                            ? 'bg-green-200 text-green-600'
                            : 'bg-red-200 text-red-600'
                        }`}
                      >
                        {executive.activo ? 'Activado' : 'Desactivado'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-2/5 pl-4">
          <div 
            className="border-2 border-dashed border-gray-300 p-4 h-full"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <h2 className="text-xl font-bold mb-4">Tareas Programadas</h2>
            {tasks.map((task) => (
              <div key={task.id} className="bg-gray-100 p-4 mb-4 rounded relative">
                <button 
                  onClick={() => handleRemoveTask(task.id)}
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
                <h3 className="font-bold mb-2">{task.executiveName}</h3>
                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">Días:</label>
                  <DaySelector
                    selectedDays={task.days}
                    onDayChange={(day) => {
                      const newDays = task.days.includes(day)
                        ? task.days.filter(d => d !== day)
                        : [...task.days, day];
                      handleTaskChange(task.id, 'days', newDays);
                    }}
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">Hora de activación:</label>
                  <input
                    type="time"
                    value={new Date(task.startTime).toTimeString().slice(0,5)}
                    onChange={(e) => {
                      const [hours, minutes] = e.target.value.split(':');
                      const newDate = new Date(task.startTime);
                  newDate.setHours(hours);
                  newDate.setMinutes(minutes);
                  handleTaskChange(task.id, 'startTime', newDate.toISOString());
                }}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Hora de desactivación:</label>
              <input
                type="time"
                value={new Date(task.endTime).toTimeString().slice(0,5)}
                onChange={(e) => {
                  const [hours, minutes] = e.target.value.split(':');
                  const newDate = new Date(task.endTime);
                  newDate.setHours(hours);
                  newDate.setMinutes(minutes);
                  handleTaskChange(task.id, 'endTime', newDate.toISOString());
                }}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
</div>
);
};
export default DashboardAdmin;